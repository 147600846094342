<template>
  <div id="label-type">
    <div ref="echarts" class="chart-box" :style="`height:${labelName.length > 7 ? labelName.length * 1 + 'rem' : '10rem' }`"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    showMore: {
      type: Boolean
    },
    labelName: {
      type: Array,
      default () {
        return []
      }
    },
    colorList: {
      type: Array,
      default () {
        return ['#387FF5', '#23D87B']
      }
    },
    dataList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      chartBar: null
    }
  },
  activated () {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartBar) {
      this.chartBar.resize()
    }
  },
  methods: {
    resize (val) {
      let nowClientWidth = document.documentElement.clientWidth
      return val * (nowClientWidth / 750)
    },
    changeFlag () {
      this.$emit('changeFlag', 3)
    },
    // 获取数据并给charts添加数据
    initChartBar () {
      let labelName = this.labelName
      if (this.chartBar) {
        this.chartBar.clear()
      }
      let obj = {
        type: 'slider',
        show: this.labelName.length > 11,
        width: 15,
        maxValueSpan: 11,
        minValueSpan: 11,
        startValue: this.labelName.length > 11 ? this.labelName.length - 11 : 0,
        brushStyle: {
          color: '#F5F9FF',
          backgroundColor: '#F5F9FF',
          opacity: 1
        },
        brushSelect: false,
        yAxisIndex: [0]
      }
      if (this.labelName.length > 12) {
        obj['maxSpan'] = 12
      }
      let that = this
      var option = {
        // dataZoom: [obj],
        grid: {
          left: this.resize(140),
          right: this.resize(150),
          bottom: this.resize(50),
          top: this.resize(50)
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          extraCssText: 'white-space: normal;',
          formatter (params) {
            console.log(params);
            let text = ''
            text +=
            `<div>
            <div style="margin: 0 .1rem; border-bottom: 1px solid #ccc; color: #4162FF;">${params[0].name}</div>
            <div style="display: flex; flex-wrap: wrap; width: 6rem;">`
            params.forEach(item => {
              text += 
              `<div style=" margin-right: .3rem; margin-top: .3rem; width: 1.5rem;">
                <p class="tooltip" style="margin: 0; width: 100%; color: ${item.color}">${item.seriesName}</p>
                <span>${item.value}</span>
              </div>`
            })
            text += `</div></div>`
            return text
          }
        },
        xAxis: {
          type: 'value',
          splitNumber: 4,
          splitLine: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          },
        },
        yAxis: {
          data: labelName.map(item => {
            if(item.length > 6) {
              item = item.slice(0, 5) + '...'
            }
            return item
          }),
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          },
          axisLabel: {
            color: '#666666',
            fontSize: this.resize(20)
          },
          axisTick: {
            show: false
          }
        },
        series: this.dataList.map((item, index) => {
          return {
            showBackground: index === 0 ? true : false,
            backgroundColor: '#F5F9FF',
            name: item.name,
            type: 'bar',
            stack: 'total',
            data: item.value,
            barWidth: this.resize(24),
            backgroundStyle: {
              borderRadius:[this.resize(20),this.resize(20), this.resize(20), this.resize(20)]
            },
            label: {
              show: index === this.dataList.length - 1 ? true : false,
              position: 'right',
              formatter (params) {
                return that.dataList.map(item => item.value[params.dataIndex]).reduce((a, b) => a + b)
              },
              offset: [0, this.resize(2)],
              fontStyle: {
                color: '#666666'
              }
            },
            itemStyle: {
              normal: {
                color: this.colorList[index],
                barBorderRadius:[this.resize(index === 0 ? 20 : 0),this.resize(index === this.dataList.length - 1 ? 20 : 0), this.resize(index === this.dataList.length - 1 ? 20 : 0), this.resize(index === 0 ? 20 : 0)]
              }
            },
          }
        })
      }
      this.chartBar = echarts.init(this.$refs.echarts)
      this.chartBar.setOption(option)
      window.addEventListener('resize', () => {
        if (this.chartBar) {
          this.chartBar.resize()
        }
      })
    }
  }
}
</script>
<style scoped>
.chart-box >>> .tooltip {
 white-space: auto !important;
}
</style>
<style lang="scss" scoped>
#label-type {
  // overflow: hidden;
  position: relative;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  box-sizing: border-box;
  .chart-box {
    z-index: 999;
  }
}
</style>

<template>
<div>
  <div><org-change @orgChange="orgChange"/></div>
  <div >
    <div class="container">
      <title-cop  :title="`${isStreet ? '街道' : '社区'}设备统计`"/>
      <div class="device_status"><device-type :list="deviceList" @change="deviceChange"/></div>
    </div>

<!-- 街道 -->
    <div class="container">
      <title-cop  :title="'各个社区' + (deviceName || '总数') + '统计'" :tip="'点击上方设备/车辆类型进行切换'" />
      <div class="user_age"><stack-bar v-if="stackBarShow" ref="stackBar" :labelName="nameList" :dataList="valueList"/></div>
    </div>
  </div>
</div>
</template>

<script>
import orgChange from './components/orgChange.vue'
import SexProgress from './components/sex-progress.vue'
import titleCop from './components/title-cop'
import totalNumber from './components/total-Number'
import UserType from './components/userType.vue'
import widthBar from './components/widthBar.vue'
import heightBar from './components/heightBar.vue'
import lineCop from './components/line-cop.vue'
import stackBar from './components/stackBar.vue'
import deviceType from './components/deviceType.vue'
export default {
  components: {
    titleCop,
    totalNumber,
    SexProgress,
    widthBar,
    UserType,
    lineCop,
    stackBar,
    heightBar,
    deviceType,
    orgChange
  },
  data () {
    return {
      orgId: 0,
      isStreet: true,
      stackBarShow: false,
      orgList: [],
      deviceList: [],
      deviceType: null,
      deviceName: '',
      type: null,
      nameList: [],
      valueList: []
    }
  },
  created () {
    this.getDeviceList()
    this.getAllInfo()
  },
  methods: {
    orgChange (id, isStreet) {
      debugger
      this.orgId = id
      this.isStreet = isStreet
      this.deviceList = []
      this.getDeviceList()
    },
    getDeviceList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/device/statisticsAll'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
            let obj = {name: '设备总数', value: 0, unit: '件', img: require('@/assets/img/statistics/device_count.png'), list: []}
          for (let key in data.data) {
            if (key == 'sum') {
              obj.value = data.data[key].num
            } else if (data.data[key].typeId != 0) {
              obj.list.push({name: data.data[key].name, value: data.data[key].num, unit: '件', id: data.data[key].typeId})
            }
          }
          this.deviceList.push(obj)
          this.getCarList()
          
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getCarList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/device/statisticsAllCar'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
            let obj = {name: '车辆总数', value: 0, unit: '辆', img: require('@/assets/img/statistics/car_count.png'), list: []}
          for (let key in data.data) {
            if (key == 'sum') {
              obj.value = data.data[key].num
            } else {
              obj.list.push({name: data.data[key].name, value: data.data[key].num, unit: '辆', id: data.data[key].typeId})
            }
          }
          this.deviceList.push(obj)
          if (this.type) {
            this.getInfo()
          } else {
            this.getAllInfo()
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getInfo () {
      this.stackBarShow = false
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/device/statisticsInfo'),
        method: 'post',
        params: this.$http.adornParams({
          type: this.type,
          otherTypeId: this.deviceType == -1 ? this.deviceList[0].list.map(item => item.id).join(',') : null,
          deviceType: this.deviceType
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.nameList = Object.keys(data.data)
          this.valueList = [{name: this.deviceName, value: []}]
          this.valueList[0].value = Object.values(data.data)
          this.stackBarShow = true
          this.$nextTick(() => {
            this.$refs.stackBar.initChartBar()
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getAllInfo () {
      this.stackBarShow = false
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/device/statisticsInfoTwo'),
        method: 'post',
        params: this.$http.adornParams({
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.nameList = Object.keys(data.data)
          this.valueList = [{name: '设备', value: []}, {name: '车辆', value: []}]
          for (let key in data.data) {
            this.valueList[0].value.push(data.data[key].device)
            this.valueList[1].value.push(data.data[key].car)
          }
          this.stackBarShow = true
          this.$nextTick(() => {
            this.$refs.stackBar.initChartBar()
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    deviceChange (index, id, name) {
      if (id) {
        this.type = index
        this.deviceType = id
        this.deviceName = name
        this.getInfo()
      } else {
        this.type = null
        this.deviceType = null
        this.deviceName = null
        this.getAllInfo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.topOrgBox {
  padding: 20px 30px;
  .topOrg {
    width: 100%;
    height: 74px;
    text-align: center;
    line-height: 74px;
    margin: 0;
    border-radius: 10px;
    background-color: #fff;
    .pull-icon {
        width: 10px;
    }
  }
}
.container {
  background-color: #fff;
  margin-bottom: 20px;
}
.split_line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #eee;
}
.user_status {
  height: 400px;
}
</style>
